import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import Carrito from '../components/Carrito';
import './productos.css';
/* IMAGENES DE PRODUCTOS IMPORTADAS*/

/*LAPTOPS*/
import img_lap1 from "./img_products/lenovo1.png"
import img_lap2 from "./img_products/lenovo2.png"
import img_lap3 from "./img_products/lenovo3.png"
import lap_asus1 from "./img_products/asus1.png"
import lap_asus2 from "./img_products/asus2.png"

/*DISCOS*/
import disk1 from "./img_products/disking-1.png"
import disk2 from "./img_products/disking2.png"
/*RAMS*/
import furyram1 from "./img_products/furyram1.png" /*esta imagen la apliqué a las dos rams de cpu*/
import lap_ram1 from "./img_products/kingstonram_lap1.png"
/*ACCESORIOS*/
import ext_disk1 from "./img_products/ext_disk1.png"
import key_log1 from "./img_products/keyboard_logi1.png"
import mouse_r1 from "./img_products/mouse_r1.png"
import secreen_1 from "./img_products/pantalla_lg1.png"
const Productos = () => {
  const [cart, setCart] = useState([]);

  const productosPorSeccion = {
    laptops: [
      { id: 1, nombre: 'LENOVO NB GAMING SHADOW BLACK 15.6 ', caracteristicas: 'asdfasdfasdf', descripcion: 'Ideal para iniciantes del Gamming y de carreras como Diseño Gráfico. Los recursos necesarios para dar el siguiente paso en tu carrera digital. GRATIS MOCHILA LENOVO IMPERMEABLE!! ', precio: 715, imagen: img_lap1 },
      { id: 2, nombre: 'LENOVO NB GAMMING LUNA GREY 15.6"', descripcion: 'Ideal para iniciantes del Gamming y de carreras como Diseño Gráfico. Los recursos necesarios para dar el siguiente paso en tu carrera digital', precio: 1150, imagen: img_lap2 },
      { id: 3, nombre: 'LENOVO Legion Slim GREY 5-16AHP 16"', descripcion: 'Todo lo que necesitas para la mejor experiencia gamer con las mejores calidades. Lo mejor en hardware para tus trabajos profesionales y renderizados que requieren altos recursos. GRATIS MOCHILA LENOVO IMPERMEABLE! ', precio: 2150, imagen: img_lap3 },
      { id: 4, nombre: 'ASUS VIVOBOOK GO 15" GRADO MILITAR ', descripcion: 'Diseño elegante y ultrafino. Excelente para personas que transportan su equipo a todos lados! su estandard de grado militar US MIL-STD 810H, certifica su durabilidad y resistencia, GRATIS MOCHILA ASUS IMPERMEABLE!.  ', precio: 1300, imagen: lap_asus1 },
      { id: 5, nombre: 'ASUS VIVOBOOK 15" GRADO MILITAR ', descripcion: 'Diseño elegante y ultrafino. Excelente para personas que transportan su equipo a todos lados! su estandard de grado militar US MIL-STD 810H, certifica su durabilidad y resistencia.', precio: 670, imagen: lap_asus2 },
    ],
    componentes: [
      { id: 6, nombre: 'DISCO SOLIDO SSD KINGSTON 240 GIGABYTES - A400', descripcion: 'Disco de 240gb ssd, para laptops, CPUs, enclousers.Ultra rapidos y faciles de instalar. .', precio: 37, imagen: disk1 },
      { id: 7, nombre: 'DISCO SOLIDO SSD KINGSTON 480 GIGABYTES - A400', descripcion: 'Disco de 240gb ssd, para laptops, CPUs, enclousers.Ultra rapidos y faciles de instalar.', precio: 45, imagen: disk2 },
      { id: 8, nombre: 'RAM KINGSTON FURY 8GB PARA PC 2666MT/seg DDR4', descripcion: ' La memoria Kingston FURY™ Beast DDR4 RGB mejora el estilo y el rendimiento de cualquier sistema. ', precio: 45, imagen: furyram1 },
      { id: 9, nombre: 'RAM KINGSTON FURY 16GB PARA PC 2666MT/seg DDR4', descripcion: 'La memoria Kingston FURY™ Beast DDR4 RGB mejora el estilo y el rendimiento de cualquier sistema.', precio: 59.99, imagen: furyram1 },
      { id: 10, nombre: 'RAM KINGSTON 8GB PARA LAPTOP 3200MT/seg DDR4', descripcion: 'La memoria RAM SO-DIMM Kingston de 8GB es la opción ideal para quienes buscan mejorar el rendimiento de sus notebooks y laptops .', precio: 35, imagen: lap_ram1 },
    ],
    accesorios: [
      { id: 11, nombre: 'DISCO PORTABLE 1TB KINGSTON ', descripcion: 'Perfecto para protejer y respaldar tus datos, ultra portable con la claidad garantizada de la marca', precio: 110, imagen: ext_disk1 },
      { id: 12, nombre: 'DISCO PORTABLE 2TB KINGSTON', descripcion: 'Perfecto para protejer y respaldar tus datos, ultra portable con la claidad garantizada de la marca.', precio: 145, imagen: ext_disk1 },
      { id: 13, nombre: 'TECLADO MECANICO LOGITECH PRO G915', descripcion: 'Sonido envolvente 7.1.', precio: 120, imagen: key_log1 },
      { id: 14, nombre: 'Razer DeathAdder Essential', descripcion: 'Comodidad y estilo con una de las mejores marcas en accesorios del mercado.', precio: 35, imagen: mouse_r1 },
      { id: 15, nombre: 'MONITOR LG HDMI/ VGA 19.5" ', descripcion: 'Monitor lg full hd 1366x768, 200 Nits. Excelente para adaptarla en tu setup de trabajo', precio: 85, imagen: secreen_1 },
    ],
  };

  const agregarAlCarrito = (producto) => {
    setCart((prevCart) => {
      const itemExistente = prevCart.find((item) => item.id === producto.id);
      if (itemExistente) {
        return prevCart.map((item) =>
          item.id === producto.id ? { ...item, cantidad: item.cantidad + 1 } : item
        );
      }
      return [...prevCart, { ...producto, cantidad: 1 }];
    });
  };

  return (
    <div className="productos-container">
      <h1 className="productos-title"></h1>

      {Object.entries(productosPorSeccion).map(([categoria, productos]) => (
        <div key={categoria} className="categoria-section">
          <h2 className="categoria-title">{categoria.toUpperCase()}</h2>

          <Swiper
            slidesPerView={1}
            spaceBetween={15}
            navigation
            autoplay={{ delay: 6000, disableOnInteraction: false }} // 🔥 Mueve cada 6 segundos automáticamente
            pagination={{ clickable: true }}
            breakpoints={{
              640: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            className="productos-slider"
            modules={[Pagination, Navigation, Autoplay]}
          >
            {productos.map((producto) => (
              <SwiperSlide key={producto.id} className="producto-slide">
                <div className="producto-card">
                  <img src={producto.imagen} alt={producto.nombre} className="producto-imagen" />
                  <h3 className="producto-nombre">{producto.nombre}</h3>
                  <p className="producto-descripcion">{producto.descripcion}</p>
                  <p className="producto-precio">${producto.precio.toFixed(2)}</p>
                  <button className="producto-boton" onClick={() => agregarAlCarrito(producto)}>
                    Agregar al Carrito
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}

      <Carrito cart={cart} setCart={setCart} />
    </div>
  );
};

export default Productos;
