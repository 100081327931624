import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Servicios from './pages/Servicios.jsx';
import Productos from './pages/Productos.jsx';
import Navbar from "./components/Navbar.jsx";
import Footer from './components/Footer.jsx';
import CasosExito from './pages/casosExito.jsx';
import BuildPC from './pages/build_pc.jsx';
import Cybersec from "./pages/Cybersec.jsx";

const AppRoutes = () => {
    return (
        <Router>
            <Navbar />
            <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                {/* Ajusta el minHeight para dejar espacio al footer */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/servicios" element={<Servicios />} />
                    <Route path="/productos" element={<Productos />} />
                    <Route path="/casosExito" element={<CasosExito />} />
                    <Route path="/build_pc" element={<BuildPC />} />
                    <Route path="/Cybersec" element={<Cybersec />} />
                </Routes>
            </div>
            <Footer />
        </Router>
        
    );
};

export default AppRoutes;
