import React from "react";
import PropTypes from "prop-types";
import "./buttonw.css"

const WhatsAppButton = ({ phoneNumber, message = "", countryCode = "US" }) => {
  // Validación básica del número
  if (!phoneNumber) {
    console.error("❌ WhatsAppButton: El número de teléfono es obligatorio.");
    return null;
  }

  // Asegurar el formato correcto del número (sin espacios ni caracteres extraños)
  const formattedNumber = phoneNumber.replace(/\D/g, ""); // Elimina caracteres no numéricos
  const formattedMessage = encodeURIComponent(message.trim()); // Codifica el mensaje correctamente

  // Enlace a WhatsApp compatible con iPhone y Android
  const whatsappLink = `https://api.whatsapp.com/send?phone=${formattedNumber}${message ? `&text=${formattedMessage}` : ""}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
      <button className="whatsapp-button">Contactar por WhatsApp</button>
    </a>
  );
};

// Definir tipos de propiedades para mayor seguridad
WhatsAppButton.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  message: PropTypes.string,
  countryCode: PropTypes.string,
};

export default WhatsAppButton;
