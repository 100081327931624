import React from 'react';
import './servicios.css';
import imgserviciotech from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/pages/servicio-tech-sechat.png"; 
import websevimg from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/pages/desarrollowebsechat.png";
import WhatsAppButton from '../components/Buttonw';


const Servicios = () => {
  const servicios = [
    {
      titulo: 'Desarrollo de Páginas Web',
      descripcion: 'Diseñamos páginas modernas, funcionales y seguras adaptadas a tus necesidades.',
      detalles: `Ofrecemos soluciones personalizadas, desde páginas estáticas hasta e-commerce completos. Tu web estará optimizada para SEO y dispositivos móviles.`,
      beneficios: [
        'Diseño responsivo',
        'Optimización SEO',
        'Desarrollo rápido y seguro',
      ],
      caracteristicas: {
        costos: 'Desde $185 USD',
        tiempos: 'Entrega en 2-3 semanas',
      },
      
      imagen: websevimg,
      contacto: {
        whatsapp: 'https://wa.me+593998476038?text=¡Hola! Estoy interesado en la creación de páginas web.',
        correo: 'mailto:ja.vh.dev@gmail.com',
      },
    },
    {
      titulo: 'Servicio Técnico',
      descripcion: 'Reparamos y optimizamos tu equipo con soluciones rápidas y confiables.',
      detalles: `Ofrecemos diagnósticos avanzados, limpieza profunda y optimización para que tu equipo funcione como nuevo.`,
      beneficios: [
        'Diagnósticos avanzados',
        'Limpieza profunda',
        'Optimización de rendimiento',
      ],
      caracteristicas: {
        costos: 'Desde $25 USD',
        tiempos: 'Entrega entre 4 horas a 1 dia dependiendo el estado de su equipo',
      },
      video: '/assets/videos/servicio-tecnico.mp4',
      imagen: imgserviciotech,
      contacto: {
        whatsapp: 'https://wa.me/+593998476038?text=¡Hola! Estoy interesado en el servicio técnico.',
        correo: 'mailto:ja.vh.dev@gmail.com',
      },
    },
  ];

  return (
    <div className="servicios-page">
      <section className="servicios-section">
        <h2>Nuestros Servicios</h2>
        {servicios.map((servicio, index) => (
          <div key={index} className={`servicio ${index % 2 === 0 ? '' : 'invertido'}`}>
            <img src={servicio.imagen} alt={servicio.titulo} className="servicio-imagen" />
            <div className="servicio-info">
              <h3>{servicio.titulo}</h3>
              <p>{servicio.descripcion}</p>
              <p><strong>Detalles:</strong> {servicio.detalles}</p>
              <h4>Beneficios:</h4>
              <ul>
                {servicio.beneficios.map((beneficio, i) => (
                  <li key={i}>{beneficio}</li>
                ))}
              </ul>
              <h4>Características:</h4>
              <p><strong>Costos:</strong> {servicio.caracteristicas.costos}</p>
              <p><strong>Tiempo de entrega:</strong> {servicio.caracteristicas.tiempos}</p>
              <div className="contacto-buttons">
                
                <WhatsAppButton phoneNumber="+593998476038" message="Hola! Quiero más información sobre este servicio!"/>
              </div>
              
            </div>
                  
          
          </div>
        ))}
      </section>
    </div>
  );
};

export default Servicios;
