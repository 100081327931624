import React, { useState, useEffect } from 'react';
import './Navbar.css';
import nav_hat from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/components/hat_nav.png"
import { Link } from 'react-router-dom';
import video_nav from "./video_intro2.mp4"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      {/* Contenedor del Video */}
      <div className="navbar-video-container">
        <video autoPlay loop muted playsInline className="navbar-video">
          <source src={video_nav} type="video/mp4" />
          Tu navegador no soporta videos en HTML5.
        </video>
        <div className="navbar-overlay"></div>
      </div>

      <div className="navbar-brand">
        <img src={nav_hat} alt="Logo" />
        <a href="/" className="logo">SEC-HAT</a>
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
  <a href="/#" className="nav-link">Inicio</a>
 {/*   <a href="/build_pc" className="nav-link">Armar mi pc</a> */}
  <Link to="/productos" className="nav-link">Ver Productos</Link>
  <a href="/servicios" className="nav-link">Servicios</a>
  {/*<a href="/casosExito" className="nav-link">InfoTech</a>*/}
  <a href="/Cybersec" className="nav-link">Ciberseguridad</a>
</div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
