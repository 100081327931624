import React, { useState } from 'react';
import './casosexito.css';

const CasosExito = () => {
  const [modalVideo, setModalVideo] = useState(null); // Estado para el video del modal

  const videos = [
    {
      id: 1,
      video: '/assets/videos/video1.mp4',
      descripcion: 'Introducción a las piezas principales de una PC y su funcionalidad.',
    },
    {
      id: 2,
      video: '/assets/videos/video2.mp4',
      descripcion: 'Explicación detallada sobre las RAMs y su importancia.',
    },
    {
      id: 3,
      video: '/assets/videos/video3.mp4',
      descripcion: 'Todo lo que necesitas saber sobre discos duros y SSD.',
    },
    {
      id: 4,
      video: '/assets/videos/video4.mp4',
      descripcion: 'Comparativa entre procesadores para diferentes necesidades.',
    },
    {
      id: 5,
      video: '/assets/videos/video5.mp4',
      descripcion: 'La importancia de la tarjeta gráfica en el rendimiento de juegos.',
    },
    {
      id: 6,
      video: '/assets/videos/video6.mp4',
      descripcion: 'Cómo elegir la fuente de poder ideal para tu PC.',
    },
    {
      id: 7,
      video: '/assets/videos/video7.mp4',
      descripcion: 'Cajas y enfriamiento: estética y funcionalidad.',
    },
    {
      id: 8,
      video: '/assets/videos/video8.mp4',
      descripcion: 'Cómo montar tu primera PC paso a paso.',
    },
  ];

  // Función para abrir el modal con el video seleccionado
  const openModal = (video) => {
    setModalVideo(video);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalVideo(null);
  };

  return (
    <div className="casos-exito-page">
      <section className="casos-exito-section">
        <h2>Información para todos</h2>
        <div className="casos-grid">
          {videos.map((video) => (
            <div className="video-card" key={video.id}>
              <video
                onClick={() => openModal(video.video)} // Abre el modal con el video correspondiente
                src={video.video}
                className="video-frame"
                alt={`Video ${video.id}`}
              ></video>
              <p>{video.descripcion}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Modal para el video */}
      {modalVideo && (
        <div className="video-modal-overlay" onClick={closeModal}>
          <div
            className="video-modal-content"
            onClick={(e) => e.stopPropagation()} // Evita que el click en el modal cierre el overlay
          >
            <button className="video-modal-close" onClick={closeModal}>
              ✖
            </button>
            <video controls src={modalVideo} className="modal-video"></video>
          </div>
        </div>
      )}
    </div>
  );
};

export default CasosExito;
