import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from 'react-router-dom';
import WhatsAppButton from "../components/Buttonw.jsx";

import "./home.css";
import img1 from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/pages/img/img1.jpg";
import img2 from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/pages/img/img2.jpg";

import img4 from "/home/cerouno-p/Documents/sechat_technologies/sh-t/src/pages/img/img4.jpg";
import servtechhome from "./soporte-tecnico.png"
import webdevhome from "./webdevsechat-home.png"
import promoshomesechat from "./promoshomesechat.png"
import laptopshome from "./laptopssechathome.png"
import alienware from "./alienwarehome.png"

import TitleSection from "../components/Tittle-section.jsx";



const images = [img1, img2, img4,];
const Home = () => {
const navigate = useNavigate();

const sections = [
  
  {
    title: "Desarrollo Web",
    description: "Creamos tu presencia en línea con diseño profesional.",
    image: webdevhome,
    link: "/Servicios",
    
  },

    
  {
    title: "Laptops",
    description: "Encuentra las mejores laptops del mercado.",
    image: laptopshome,
    link: "/productos"
  },

  {
    title: "Servicio Técnico",
    description: "Reparación y mantenimiento garantizados.",
    image: servtechhome,
    link: "/Servicios",
  }, 
  
  {
    title: "Promociones",
    description: "Descubre nuestras ofertas exclusivas.",
    image: promoshomesechat,
    link: "/productos",
  },
];
return (
    <div className="home-container">

<TitleSection/>

<section className="promo-section">
<div className="promo-content">
     
    </div>

     
    <section className="features-section">
      <div className="feature-card">
        <h2> Contacto en 2 clics</h2>
        <h3>📲 Diseños adaptados a móviles y tablets</h3>
        <p> Tus clientes se contactarán directamente a tu número de negocio!</p>
      </div>

      <div className="feature-card">
        <h2>Estrategia digital competitiva</h2>
        <h3>💡Destacamos tu negocio internet.</h3>
        <p>Nos aseguramos de que tu marca tenga una presencia optimizada en la web para que destaces sobre la competencia.</p>
      </div>

      <div className="feature-card">
        <h2>Desarrollo UX/UI incluido </h2>
        <h3>⚙️ Digitalizamos tu negocio a medida</h3>
        <p>Creamos experiencias de usuario intuitivas y atractivas, lo que significa más clientes y conversiones para ti.</p>
      </div>

      <div className="feature-card">
        <h2> Seguridad en la Red</h2>
        <h3>🔒 Páginas seguras con certificación SSL </h3>
        <p>Nos aseguramos de la seguridad de tu Web para evitar ataques de cibercriminales.</p>
      </div>
    </section>
  
  


    </section>

    <section className="pc-setup-section">
            <div className="pc-setup-container">
                {/* Swiper (Imágenes a la izquierda) */}
                <div className="swiper-wrapper">
                    <Swiper
                        className="images-slider"
                        modules={[Pagination, Autoplay]}
                        spaceBetween={20}
                        slidesPerView={1}
                        autoplay={{ delay: 8000 }} // 8 segundos por slide
                        pagination={{ clickable: true }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={image} alt={`PC Setup ${index + 1}`} className="image-slide" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Texto a la derecha */}
                <div className="text-wrapper">
                    
                    <p className="fade-in delay-1">
                    🖥️  Personaliza tu PC a medida, con componentes de alta calidad y rendimiento, ideal para gaming, diseño o trabajo profesional.
                    </p>
                    <h3 className="fade-in delay-2">⚡ Configuración a tu gusto</h3>
                    <h3 className="fade-in delay-3">🚀 Entrega rápida y segura</h3>
                    <h3 className="fade-in delay-4">🛡️ Componentes 100% originales</h3>
                    <h3 className="fade-in delay-5">📈 Optimizado para máximo rendimiento</h3>

                    <button
                        className="intro-button fade-in delay-6"
                        onClick={() => window.open("https://wa.me/0998476038?text=¡Hola!%20Quiero%20ver%20el%20catálogo%20de%20PC%20Setups.", "_blank")}
                    >
                        Solicitar Catálogo
                    </button>
                </div>
            </div>
        </section>

{/*  */}
{/* seccion laptops  */}
<section className="laptops-section">
  <div className="laptops-container">
    <div className="laptops-content">
      
     
      <p className="laptops-description">
      💻 Descubre nuestras laptops modernas con rendimiento superior,perfectas para trabajo, estudio y entretenimiento. 🚀
      </p>
     
      <ul className="laptops-benefits">
        <li>⚡ Procesadores de última generación</li>
        <li>🎨 Pantallas Full HD con colores vibrantes</li>
        <li>🔋 Autonomía para todo el día</li>
        <li>🛡️ Seguridad avanzada con lector de huellas</li>
      </ul>
      
      <a href="/productos" className="laptops-button">Ver Productos 🚀</a>
    </div>
    <div className="laptops-image">
      <img src= {alienware} alt="Laptop moderna" />
    </div>
    
  </div>
</section>
{/* Sección de Información */}
<section className="info-section">
      <h2>Secciones</h2>
      <div className="info-grid">
        {sections.map((section, index) => (
          <div
            className="info-card"
            key={index}
            onClick={() => window.location.href = section.link}
          >
            <img src={section.image} alt={section.title} />
            <div className="info-card-content">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
          </div>
        ))}
      </div>
    
    </section>


    <section class="brands-section">
  <h2>Nuestras Marcas  </h2>
  <div class="brands-carousel">
    <a href="https://lenovo.com" target="_blank" class="brand-item brand-item-1"></a>
    <a href="https://dell.com" target="_blank" class="brand-item brand-item-2"></a>
    <a href="https://www.instagram.com/alienwarelatam/?hl=es" target="_blank" class="brand-item brand-item-3"></a>
    <a href="https://www.asus.com/latin/" target="_blank" class="brand-item brand-item-4"></a>
    <a href="https://www.kingston.com/latam" target="_blank" class="brand-item brand-item-5"></a>
    <a href="https://www.corsair.com" target="_blank" class="brand-item brand-item-6"></a>
    <a href="https://www.logitech.com" target="_blank" class="brand-item brand-item-7"></a>
    <a href="https://www.razer.com" target="_blank" class="brand-item brand-item-8"></a>
  </div>
</section>



        

      {/* Sección de Casos de Éxito 
      <section className="success-stories-section">
        <h2>Casos de Éxito</h2>
        <div className="success-stories">
          <div className="success-story">
            <h3>Juan Pérez</h3>
            <p>
              "Gracias a TechStore, pude encontrar la laptop perfecta para mis
              estudios. Excelente calidad y servicio."
            </p>
          </div>
          <div className="success-story">
            <h3>María García</h3>
            <p>
              "Compré un smartphone y llegó súper rápido. 100% recomendable."
            </p>
          </div>
          <div className="success-story">
            <h3>Empresa XYZ</h3>
            <p>
              "TechStore equipó nuestra oficina con tecnología de punta.
              Excelente experiencia de compra."
            </p>
          </div>
        </div>
      </section>
      */}
    </div>
  );
};

export default Home;

