import React, { useState } from 'react';
import './build_pc.css';
import CarritoArmadoPC from '../components/CarritoArmadoPC';

const components = {
  procesadores: [
    { id: 1, nombre: 'Intel i7 12700K', precio: 400, imagen: 'marca1.png', video: 'https://www.intel.la/content/www/xl/es/products/details/processors.html', descripcion: 'Procesador de alto rendimiento para gaming y productividad.' },
    { id: 2, nombre: 'AMD Ryzen 7 5800X', precio: 350, imagen: 'imagen2.jpg', video: 'https://youtu.be/procesador2', descripcion: 'Procesador eficiente con excelente relación calidad-precio.' },
  ],
  rams: [
    { id: 3, nombre: 'Corsair Vengeance 16GB', precio: 100, imagen: 'imagen3.jpg', video: 'https://youtu.be/ram1', descripcion: 'Memoria RAM rápida y confiable para multitarea.' },
    { id: 4, nombre: 'Kingston Fury 16GB', precio: 90, imagen: 'imagen4.jpg', video: 'https://youtu.be/ram2', descripcion: 'RAM asequible y eficiente para cualquier sistema.' },
  ],
  motherboards: [
    { id: 5, nombre: 'ASUS ROG Strix Z690-E', precio: 300, imagen: 'imagen5.jpg', video: 'https://youtu.be/motherboard1', descripcion: 'Placa base premium para overclocking y personalización.' },
    { id: 6, nombre: 'MSI MPG B550 Gaming Edge', precio: 250, imagen: 'imagen6.jpg', video: 'https://youtu.be/motherboard2', descripcion: 'Ideal para gamers con soporte para procesadores AMD.' },
  ],
  graficas: [
    { id: 7, nombre: 'NVIDIA RTX 3070', precio: 600, imagen: 'imagen7.jpg', video: 'https://youtu.be/grafica1', descripcion: 'Gráfica potente para gaming y diseño gráfico.' },
    { id: 8, nombre: 'AMD Radeon RX 6700 XT', precio: 500, imagen: 'imagen8.jpg', video: 'https://youtu.be/grafica2', descripcion: 'Excelente opción para gamers con presupuesto medio.' },
  ],
  pantallas: [
    { id: 9, nombre: 'LG UltraGear 27"', precio: 250, imagen: 'imagen9.jpg', video: 'https://youtu.be/pantalla1', descripcion: 'Monitor de alta tasa de refresco y calidad de imagen.' },
    { id: 10, nombre: 'Dell 24" FHD', precio: 200, imagen: 'imagen10.jpg', video: 'https://youtu.be/pantalla2', descripcion: 'Monitor Full HD con gran relación calidad-precio.' },
  ],
  fuentes: [
    { id: 11, nombre: 'Corsair RM750x', precio: 120, imagen: 'imagen11.jpg', video: 'https://youtu.be/fuente1', descripcion: 'Fuente de poder confiable con eficiencia 80+ Gold.' },
    { id: 12, nombre: 'EVGA 650W Bronze', precio: 80, imagen: 'imagen12.jpg', video: 'https://youtu.be/fuente2', descripcion: 'Fuente económica con certificación 80+ Bronze.' },
  ],
  coolers: [
    { id: 13, nombre: 'Noctua NH-D15', precio: 100, imagen: 'imagen13.jpg', video: 'https://youtu.be/cooler1', descripcion: 'Refrigeración silenciosa y eficiente.' },
    { id: 14, nombre: 'Cooler Master Hyper 212', precio: 50, imagen: 'imagen14.jpg', video: 'https://youtu.be/cooler2', descripcion: 'Una opción económica para mantener tu CPU fresco.' },
  ],
  discos: [
    { id: 15, nombre: 'Samsung 970 EVO 1TB', precio: 130, imagen: 'imagen15.jpg', video: 'https://youtu.be/disco1', descripcion: 'SSD rápido para carga de sistemas y juegos.' },
    { id: 16, nombre: 'WD Blue 1TB HDD', precio: 50, imagen: 'imagen16.jpg', video: 'https://youtu.be/disco2', descripcion: 'HDD confiable para almacenamiento masivo.' },
  ],
  cases: [
    { id: 17, nombre: 'NZXT H510', precio: 100, imagen: 'imagen17.jpg', video: 'https://youtu.be/case1', descripcion: 'Chasis minimalista y elegante para tu setup.' },
    { id: 18, nombre: 'Cooler Master TD500', precio: 110, imagen: 'imagen18.jpg', video: 'https://youtu.be/case2', descripcion: 'Diseño atractivo con gran flujo de aire.' },
  ],
  ventiladores: [
    { id: 19, nombre: 'Corsair LL120 RGB', precio: 40, imagen: 'imagen19.jpg', video: 'https://youtu.be/ventilador1', descripcion: 'Ventilador RGB con alto rendimiento y personalización.' },
    { id: 20, nombre: 'ARCTIC F12', precio: 20, imagen: 'imagen20.jpg', video: 'https://youtu.be/ventilador2', descripcion: 'Ventilador económico con buen rendimiento térmico.' },
  ],
};
const BuildPC = () => {
  const [cart, setCart] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [modalData, setModalData] = useState(null);

  const addToCart = (component) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === component.id);
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === component.id
            ? { ...item, cantidad: item.cantidad + 1 }
            : item
        );
      }
      return [...prevCart, { ...component, cantidad: 1 }];
    });
  };

  const openModal = (component) => setModalData(component);
  const closeModal = () => setModalData(null);

  return (
    <div className="build-pc-page">
      <section className="build-section">
        <h2>Arma tu PC</h2>
        <h3>O upgradea sus caracteristicas</h3>
        <div className="categories">
          {Object.keys(components).map((category) => (
            <div key={category} className="category">
              <h3>{category.toUpperCase()}</h3>
              <ul>
                {components[category].map((component) => (
                  <li
                    key={component.id}
                    onMouseEnter={() => setHoveredItem(component)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <button
                      onClick={() => openModal(component)}
                      className="component-button"
                    >
                      {component.nombre} - ${component.precio}
                    </button>
                    {hoveredItem?.id === component.id && (
                      <div className="hover-preview">
                        <div className="preview-content">
                          <img src={hoveredItem.imagen} alt={hoveredItem.nombre} />
                          <h3>{hoveredItem.nombre}</h3>
                          <p>{hoveredItem.descripcion}</p>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      {/* Carrito de armado de PC */}
      <CarritoArmadoPC cart={cart} setCart={setCart} />

      {/* Modal de selección */}
      {modalData && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalData.imagen} alt={modalData.nombre} />
            <h3>{modalData.nombre}</h3>
            <p>{modalData.descripcion}</p>
            <button onClick={() => addToCart(modalData)} className="select-button">
              Agregar al Carrito
            </button>
            <button onClick={closeModal} className="close-button">
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildPC;