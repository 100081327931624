import React from 'react';
import './cybersec.css'; // Importamos el CSS
import WhatsAppButton from '../components/Buttonw';

/*IMAGENES DE LA SECCION CYBERSEGURIDAD*/
import auditweb1 from "./auditoriaweb1.png"
import auditlocal1 from "./auditlocal1.png"
import audithome1 from "./audithome1.png"
import pishing1 from "./pishingexample1.png"
import ransom1 from "./ransom1example.png"
import ddos1 from "./ddosexample1.png"
import maninmddl1 from "./manmiddl1example1.png"
import DynamicButton from '../components/Buttonw';


const CybersecuritySection = () => {
  return (
    <div className="cybersecurity-section">
      {/* Sección de Servicios */}
      
      <section className="services-section">
        <h1 className="section-title">Ciberseguridad</h1>
        
        <div className="service-item">
          <div className="service-image">
            <img src={auditweb1} alt="Auditoría Web" />
          </div>
          <div className="service-content">
            <h2>Auditoría Web</h2>
            <p>
              Identificamos vulnerabilidades en tu sitio web, como inyecciones SQL, XSS y configuraciones inseguras.
              Garantizamos la protección de tus datos y la integridad de tu plataforma.
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="service-item">
          <div className="service-image">
            <img src={auditlocal1} alt="Auditoría de Redes Locales" />
          </div>
          <div className="service-content">
            <h2>Auditoría de Redes Locales</h2>
            <p>
              Analizamos la seguridad de tu red local, detectando puntos débiles en firewalls, routers y dispositivos conectados.
              Prevenimos accesos no autorizados y fugas de información.
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="service-item">
          <div className="service-image">
            <img src={audithome1} alt="Análisis de Redes de Hogar" />
          </div>
          <div className="service-content">
            <h2>Análisis de Redes de Hogar</h2>
            <p>
              Evaluamos la seguridad de tu red doméstica, protegiendo dispositivos IoT, routers y conexiones Wi-Fi.
              Te ayudamos a evitar intrusiones y robos de información.
            </p>
           
          </div>          
       
        
        </div>
        <WhatsAppButton phoneNumber="+593998476038" message="Hola! Quiero más información sobre las auditorias de seguridad informatica con Sec-Hat." />
      </section>
      
      {/* Sección de Ataques Comunes */}
      <section className="attacks-section">
        <h1 className="section-title">Ataques Comunes</h1>
        <div className="attack-item">
          <div className="attack-image">
            <img src={pishing1} alt="Phishing" />
          </div>
          <div className="attack-content">
            <h2>Phishing</h2>
            <p>
              Los atacantes envían correos falsos o mensajes para robar credenciales. Es crucial educar a los usuarios y usar filtros anti-phishing.
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="attack-item">
          <div className="attack-image">
            <img src={ransom1} alt="Ransomware" />
          </div>
          <div className="attack-content">
            <h2>Ransomware</h2>
            <p>
              Malware que cifra tus archivos y exige un rescate. Mantén copias de seguridad y actualiza tus sistemas para prevenirlo.
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="attack-item">
          <div className="attack-image">
            <img src={ddos1} alt="DDoS" />
          </div>
          <div className="attack-content">
            <h2>Ataques DDoS</h2>
            <p>
              Sobrecargan tus servidores con tráfico falso, dejándolos inaccesibles. Usa servicios de mitigación y firewalls avanzados.
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <div className="attack-item">
          <div className="attack-image">
            <img src={maninmddl1} alt="Man-in-the-Middle" />
          </div>
          <div className="attack-content">
            <h2>Man-in-the-Middle</h2>
            <p>
              Los atacantes interceptan comunicaciones entre dos partes. Usa cifrado SSL/TLS y evita redes Wi-Fi públicas no seguras.
            </p>
          </div>
        </div>
      </section>

      {/* Sección de Noticias */}
      <section className="news-section">
        <h1 className="section-title">Noticias Relevantes</h1>
        <div className="news-item">
          <h2>Aumento de ciberataques a empresas en 2023</h2>
          <p>
            Según un informe reciente, los ciberataques a empresas han aumentado un 40% en comparación con el año pasado.
          </p>
        </div>
        <div className="divider"></div>
        <div className="news-item">
          <h2>Nuevas regulaciones de protección de datos en la UE</h2>
          <p>
            La UE ha introducido nuevas normativas para fortalecer la protección de datos personales y sancionar a las empresas que no cumplan.
          </p>
        </div>
        <div className="divider"></div>
        <div className="news-item">
          <h2>Ransomware afecta a hospitales en EE.UU.</h2>
          <p>
            Varios hospitales han sido víctimas de ransomware, lo que ha paralizado sus operaciones y puesto en riesgo la vida de pacientes.
          </p>
        </div>
        <div className="divider"></div>
        <div className="news-item">
          <h2>Avances en inteligencia artificial para detectar malware</h2>
          <p>
            Nuevas herramientas de IA están siendo utilizadas para identificar y neutralizar malware de manera más eficiente.
          </p>
        </div>
      </section>
    </div>
  );
};

export default CybersecuritySection;