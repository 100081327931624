import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import { FaInstagram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import Logo from "./hat_nav.png"; // Asegúrate de que la ruta del logo sea correcta

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="footer-container">
                
                {/* 🔹 LOGO Y BOTONES A LA IZQUIERDA */}
                <div className="footer-left">
                    <div className="footer-logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <nav className="footer-nav">
                        <button className="footer-button" onClick={() => navigate("/")}>Inicio</button>
                        <button className="footer-button" onClick={() => navigate("/productos")}>Productos</button>
                        <button className="footer-button" onClick={() => navigate("/servicios")}>Servicios</button>
                        <button className="footer-button" onClick={() => navigate("/Cybersec")}>Ciberseguridad</button>
                    </nav>
                </div>

                
                

                {/* 🔹 REDES SOCIALES A LA DERECHA */}
                <div className="footer-socials">
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagram /></a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedinIn /></a>
                    <a href="https://wa.me/0998476038" target="_blank" rel="noopener noreferrer" className="social-icon"><FaWhatsapp /></a>
                </div>

            </div>

            {/* 🔹 Derechos Reservados */}
            <p className="footer-copy">&copy; {new Date().getFullYear()} SECHAT | Todos los derechos reservados.</p>
        </footer>
    );
};

export default Footer;
