import React, { useState, useEffect } from "react";
import "./tittle-section.css"
import WhatsAppButton from "./Buttonw";



const TitleSection = () => {
  const textos = [
    "🚀 Impulsa tu negocio con una web profesional.",
    "🎯 Llega a más clientes y aumenta tus ventas.",
    "💡 Tu web lista en tiempo récord, sin complicaciones.",
  ];

  const [indice, setIndice] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndice((prev) => (prev + 1) % textos.length);
    }, 4000); // 👈 Cambia cada 4 segundos

    return () => clearInterval(intervalo);
  }, []);

  return (
    <div className="title-section">
      <div className="title-content">
        <h1 className="text-effect">Digitalízate Ya!</h1>
        <h2 key={indice} className="changing-subtitle">
          {textos[indice]}
        </h2>
        <WhatsAppButton phoneNumber="+593998476038" message="Hola! Quiero mi página web con Sec-Hat!." />
      </div>
    </div>
  );
};

export default TitleSection;
