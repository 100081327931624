import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import './carrito.css';
import logo from './hat_nav.png'; // Importa el logo desde tu carpeta de assets

const Carrito = ({ cart, setCart }) => {
  const [mostrarCarrito, setMostrarCarrito] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [formulario, setFormulario] = useState({
    nombre: '',
    celular: '',
    correo: '',
    direccion: '',
    comentarios: '',
  });

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const incrementarCantidad = (id) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id ? { ...item, cantidad: item.cantidad + 1 } : item
      )
    );
  };

  const decrementarCantidad = (id) => {
    setCart((prevCart) =>
      prevCart
        .map((item) => (item.id === id ? { ...item, cantidad: item.cantidad - 1 } : item))
        .filter((item) => item.cantidad > 0)
    );
  };

  const manejarCambio = (e) => {
    setFormulario({ ...formulario, [e.target.name]: e.target.value });
  };

  const generarPDF = () => {
    const doc = new jsPDF();

    // Agregar logo
    doc.addImage(logo, 'PNG', 10, 10, 50, 20); // Ajusta la posición y tamaño del logo

    // Título
    doc.setFontSize(16);
    doc.text('Resumen de Compra', 105, 20, null, null, 'center');

    // Datos del Cliente
    doc.setFontSize(12);
    doc.text(`Nombre: ${formulario.nombre}`, 20, 40);
    doc.text(`Celular: ${formulario.celular}`, 20, 50);
    doc.text(`Correo: ${formulario.correo}`, 20, 60);
    doc.text(`Dirección: ${formulario.direccion || 'No proporcionada'}`, 20, 70);
    doc.text(`Comentarios: ${formulario.comentarios || 'Sin comentarios'}`, 20, 80);

    // Detalles del carrito
    doc.text('Productos:', 20, 90);
    let yPosition = 100;
    cart.forEach((item, index) => {
      doc.text(
        `${index + 1}. ${item.nombre} - Cantidad: ${item.cantidad} - Total: $${(
          item.cantidad * item.precio
        ).toFixed(2)}`,
        20,
        yPosition
      );
      yPosition += 10;
    });

    // Total
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Total: $${cart
        .reduce((acc, item) => acc + item.precio * item.cantidad, 0)
        .toFixed(2)}`,
      20,
      yPosition + 10
    );

    // Descargar PDF
    doc.save('resumen-compra.pdf');

    return doc;
  };

  const enviarFormulario = () => {
    if (!formulario.nombre || !formulario.celular || !formulario.correo) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    // Generar PDF
    const pdf = generarPDF();

    // Convertir el PDF a Blob para WhatsApp
    const pdfBlob = pdf.output('blob');
    const file = new File([pdfBlob], 'resumen-compra.pdf', { type: 'application/pdf' });
    const formData = new FormData();
    formData.append('file', file);

    // Simular enlace de WhatsApp con el resumen en texto
    const mensaje = `
      Hola, te comparto mi pedido:
      *Nombre*: ${formulario.nombre}
      *Celular*: ${formulario.celular}
      *Correo*: ${formulario.correo}
      *Dirección*: ${formulario.direccion || 'No proporcionada'}
      *Comentarios*: ${formulario.comentarios || 'Sin comentarios'}

      *Resumen del Pedido:*
      ${cart
        .map(
          (item) =>
            `- ${item.nombre} (Cantidad: ${item.cantidad}) - $${(
              item.cantidad * item.precio
            ).toFixed(2)}`
        )
        .join('\n')}
      
      *Total: $${cart
        .reduce((acc, item) => acc + item.precio * item.cantidad, 0)
        .toFixed(2)}*
    `;
    const mensajeCodificado = encodeURIComponent(mensaje);
    const urlWhatsapp = `https://wa.me/593998476038?text=${mensajeCodificado}`;

    // Abrir WhatsApp con el mensaje
    window.open(urlWhatsapp, '_blank');
    setMostrarFormulario(false);
  };

  return (
    <>
      {/* Botón del carrito */}
      <button
        className="carrito-boton"
        onClick={() => setMostrarCarrito((prev) => !prev)}
      >
        🛒
      </button>

      {/* Carrito desplegable */}
      <div className={`carrito-lateral ${mostrarCarrito ? 'mostrar' : ''}`}>
        <div className="carrito-header">
          <h2>Carrito</h2>
          <button
            className="carrito-cerrar"
            onClick={() => setMostrarCarrito(false)}
          >
            ✖
          </button>
        </div>

        <div className="carrito-body">
          {cart.length === 0 ? (
            <p className="carrito-vacio">No tienes productos en tu carrito.</p>
          ) : (
            cart.map((item) => (
              <div className="carrito-item" key={item.id}>
                <img src={item.imagen} alt={item.nombre} className="carrito-imagen" />
                <div className="carrito-detalles">
                  <p className="carrito-nombre">{item.nombre}</p>
                  <p className="carrito-precio">${item.precio.toFixed(2)}</p>
                  <div className="carrito-controles">
                    <button
                      className="btn-menos"
                      onClick={() => decrementarCantidad(item.id)}
                    >
                      −
                    </button>
                    <span className="carrito-cantidad">{item.cantidad}</span>
                    <button
                      className="btn-mas"
                      onClick={() => incrementarCantidad(item.id)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <button
                  className="btn-eliminar-x"
                  onClick={() => removeFromCart(item.id)}
                >
                  ✖
                </button>
              </div>
            ))
          )}
        </div>

        {cart.length > 0 && (
          <div className="carrito-footer">
            <p className="carrito-total">
              Total: $
              {cart
                .reduce((acc, item) => acc + item.precio * item.cantidad, 0)
                .toFixed(2)}
            </p>
            <button
              className="carrito-comprar"
              onClick={() => setMostrarFormulario(true)}
            >
              Finalizar Compra
            </button>
          </div>
        )}
      </div>

      {/* Formulario emergente */}
      {mostrarFormulario && (
        <div className="formulario-overlay">
          <div className="formulario-modal">
            <h3>Ingrese sus datos</h3>
            <input
              type="text"
              name="nombre"
              placeholder="Nombres y Apellidos"
              value={formulario.nombre}
              onChange={manejarCambio}
            />
            <input
              type="text"
              name="celular"
              placeholder="Número de Celular / WhatsApp"
              value={formulario.celular}
              onChange={manejarCambio}
            />
            <input
              type="email"
              name="correo"
              placeholder="Correo Electrónico"
              value={formulario.correo}
              onChange={manejarCambio}
            />
            <textarea
              name="direccion"
              placeholder="Datos de Envío (opcional)"
              value={formulario.direccion}
              onChange={manejarCambio}
            />
            <textarea
              name="comentarios"
              placeholder="Comentarios opcionales"
              value={formulario.comentarios}
              onChange={manejarCambio}
            />
            <button className="btn-comprar" onClick={enviarFormulario}>
              Comprar/Cotizar
            </button>
            <button
              className="btn-cancelar"
              onClick={() => setMostrarFormulario(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Carrito;
