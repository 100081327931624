import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "./Carritoarmadopc.css";

const CarritoArmadoPC = ({ cart, setCart }) => {
  const [mostrarCarrito, setMostrarCarrito] = useState(false);
  const [faltantes, setFaltantes] = useState([]);
  const [mostrarAdvertencia, setMostrarAdvertencia] = useState(false);
  const [procesoArmado, setProcesoArmado] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [datosCliente, setDatosCliente] = useState({ nombre: "", direccion: "", telefono: "" });

  // Componentes esenciales requeridos para validar la compra
  const componentesRequeridos = [
    "Placa Madre",
    "RAM",
    "Fuente de Energía",
    "Procesador",
    "Tarjeta de Video",
    "Case",
    "Disco Duro",
    "Cooler",
    "Ventiladores",
  ];

  // Verificar qué componentes esenciales faltan antes de la compra
  const validarComponentes = () => {
    const nombresEnCarrito = cart.map((item) => item.nombre);
    const faltantesTemp = componentesRequeridos.filter(
      (requerido) => !nombresEnCarrito.includes(requerido)
    );

    setFaltantes(faltantesTemp);
    return faltantesTemp.length === 0;
  };

  // Generar el PDF con la información de la compra y del cliente
  const generarPDF = () => {
    const doc = new jsPDF();
    doc.text("Resumen de Compra", 20, 20);
    doc.text(`Cliente: ${datosCliente.nombre}`, 20, 30);
    doc.text(`Dirección: ${datosCliente.direccion}`, 20, 40);
    doc.text(`Teléfono: ${datosCliente.telefono}`, 20, 50);

    cart.forEach((item, index) => {
      doc.text(`${index + 1}. ${item.nombre} x ${item.cantidad}`, 20, 60 + index * 10);
    });

    if (procesoArmado) {
      doc.text("Incluye proceso de armado: +$30", 20, 70 + cart.length * 10);
    }

    doc.save("resumen_compra.pdf");
  };

  // Enviar el resumen por WhatsApp al número 0998476038
  const enviarPorWhatsApp = () => {
    const mensaje = `Pedido de ${datosCliente.nombre}%0A
Dirección: ${datosCliente.direccion}%0A
Teléfono: ${datosCliente.telefono}%0A
Productos:%0A${cart
      .map((item) => `- ${item.nombre} x${item.cantidad}`)
      .join("%0A")}%0A
Total: $${procesoArmado ? totalFinal : total}%0A
${procesoArmado ? "✔️ Incluye proceso de armado" : ""}`;

    const url = `https://wa.me/0998476038?text=${mensaje}`;
    window.open(url, "_blank");
  };

  // Acción al presionar "Comprar"
  const handleComprar = () => {
    if (validarComponentes()) {
      setMostrarFormulario(true);
    } else {
      setMostrarAdvertencia(true);
    }
  };

  // Confirmar compra después de ingresar los datos
  const confirmarCompra = () => {
    generarPDF();
    enviarPorWhatsApp();
    alert("Compra realizada con éxito. ¡Gracias!");
    setMostrarFormulario(false);
  };

  // Calcular total con o sin proceso de armado
  const total = cart.reduce((acc, item) => acc + item.precio * item.cantidad, 0);
  const totalFinal = procesoArmado ? total + 30 : total;

  return (
    <>
      {/* Botón flotante del carrito */}
      <button className="carrito-boton" onClick={() => setMostrarCarrito((prev) => !prev)}>
        🛒
      </button>

      {/* Carrito lateral */}
      <div className={`carrito-lateral ${mostrarCarrito ? "mostrar" : ""}`}>
        <div className="carrito-header">
          <h2>Carrito de Armado</h2>
          <button className="carrito-cerrar" onClick={() => setMostrarCarrito(false)}>
            ✖
          </button>
        </div>

        <div className="carrito-body">
          {cart.length === 0 ? (
            <p className="carrito-vacio">No tienes productos en tu carrito.</p>
          ) : (
            cart.map((item) => (
              <div className="carrito-item" key={item.id}>
                <p className="carrito-nombre">{item.nombre}</p>
                <p className="carrito-precio">${item.precio.toFixed(2)}</p>
              </div>
            ))
          )}
        </div>

        {cart.length > 0 && (
          <div className="carrito-footer">
            <p className="carrito-total">Total: ${totalFinal.toFixed(2)}</p>

            <button
              className={`boton-armado ${procesoArmado ? "activo" : ""}`}
              onClick={() => setProcesoArmado((prev) => !prev)}
            >
              {procesoArmado ? "✔️ Proceso de Armado Añadido" : "Ver Proceso de Armado (+$30)"}
            </button>

            <button onClick={handleComprar} className="carrito-comprar">
              Comprar
            </button>

            {mostrarAdvertencia && (
              <div className="mensaje-advertencia">
                <p>❗ Faltan componentes esenciales:</p>
                <ul>
                  {faltantes.map((comp) => (
                    <li key={comp}>{comp}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Formulario emergente para datos del cliente */}
      {mostrarFormulario && (
        <div className="formulario-overlay">
          <div className="formulario-modal">
            <h3>Datos del Cliente</h3>
            <input
              type="text"
              placeholder="Nombre"
              value={datosCliente.nombre}
              onChange={(e) => setDatosCliente({ ...datosCliente, nombre: e.target.value })}
            />
            <input
              type="text"
              placeholder="Dirección"
              value={datosCliente.direccion}
              onChange={(e) => setDatosCliente({ ...datosCliente, direccion: e.target.value })}
            />
            <input
              type="text"
              placeholder="Teléfono"
              value={datosCliente.telefono}
              onChange={(e) => setDatosCliente({ ...datosCliente, telefono: e.target.value })}
            />
            <button onClick={confirmarCompra} className="btn-comprar">
              Confirmar Compra
            </button>
            <button onClick={() => setMostrarFormulario(false)} className="btn-cancelar">
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CarritoArmadoPC;
